<template>
  <li>
    <item-card>    
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
          Pamatdati
        </h3>
      </template>

      <template v-slot:buttons>
        <template v-if="!displayForm('EditInventoryItemDetails') && !displayForm('ChangeInventoryItemOwner')">
          <span class="relative z-0 inline-flex rounded-md ml-3">

            <template v-if="item.allow_change_owner">
              <Button icon="briefcase-edit" @click="showForm('ChangeInventoryItemOwner')" />
            </template>
            
            <Button icon="pencil" @click="showForm('EditInventoryItemDetails')" />

          </span>
        </template>
      </template>

      <template v-slot:content>

        <template v-if="!displayForm('EditInventoryItemDetails') && !displayForm('ChangeInventoryItemOwner')">
          <ShowInventoryItemDetails :item="item"/>
        </template>

        <template v-if="displayForm('EditInventoryItemDetails')">
          <EditInventoryItemDetails :item="item" />
        </template>

        <template v-if="displayForm('ChangeInventoryItemOwner')">
          <ChangeInventoryItemOwner :item="item" />
        </template>

      </template>

      <template v-slot:additional>
        <template v-if="!displayForm('EditInventoryItemDetails') && !displayForm('ChangeInventoryItemOwner')">
          <ShowInventoryItemNotes :item="item" />
        </template>
      </template>

    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex";
import { defineAsyncComponent } from 'vue'

const ItemCard = defineAsyncComponent(() =>
  import('@/components/Components/ItemCard'))
const ShowInventoryItemDetails = defineAsyncComponent(() =>
  import('@/components/Stock/Inventory/InventoryItem/ShowInventoryItemDetails'))
const EditInventoryItemDetails = defineAsyncComponent(() =>
  import('@/components/Stock/Inventory/InventoryItem/EditInventoryItemDetails'))
const ChangeInventoryItemOwner = defineAsyncComponent(() =>
  import('@/components/Stock/Inventory/InventoryItem/ChangeInventoryItemOwner'))
const ShowInventoryItemNotes = defineAsyncComponent(() =>
  import('@/components/Stock/Inventory/InventoryItem/ShowInventoryItemNotes'))

export default {
  name: "InventoryItemDetails",
  components: {
    ItemCard,
    ShowInventoryItemDetails,
    EditInventoryItemDetails,
    ChangeInventoryItemOwner,
    ShowInventoryItemNotes,
  },
  props: ['item'],
  data: () => ({
    showDetails: false
  }),
  computed: {
    ...mapGetters({
        formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },

  }
}
</script>